import { render, staticRenderFns } from "./ImportModelSelector.vue?vue&type=template&id=6f22c808"
import script from "./ImportModelSelector.vue?vue&type=script&lang=js"
export * from "./ImportModelSelector.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports