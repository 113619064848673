<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <h3>{{ $t("IMPORTS.RESULTS") }}</h3>
    </div>
    <import-results :importResults="importation.results" />
  </div>
</template>

<script>
import ImportResults from "./ImportResults";

export default {
  components: {
    ImportResults,
  },

  props: ["importation"],

  watch: {},
};
</script>
