import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  data() {
    return {
      alertLeave: false,
    };
  },

  methods: {},

  beforeRouteLeave(to, from, next) {
    if (this.alertLeave) {
      swal
        .fire({
          text: this.$t("COMMON.DISCARD_CHANGES_AND_LEAVE_PAGE"),
          type: "question",
          customClass: {
            popup: "warning-confirm",
          },
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText: this.$t("COMMON.YES"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        })
        .then((confirmation) => {
          if (confirmation.value === true) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
  },
};
